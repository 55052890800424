import React from 'react'
import USDefaultTemplate from 'src/components/template/us'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      poster={'https://assets.fubo.tv/marketing/lp/images/posters/Bulls_LP_Static_Web_V2.jpg'}
      posterMobile={'https://assets.fubo.tv/marketing/lp/images/posters/Bulls_LP_Static_Mobile.jpg'}
      title="Watch Chicago basketball live"
      subtitle="STREAM THE BULLS ON NBC SPORTS CHICAGO"
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      networkSet="basketball"
      customNetworks={['nbcschicago']}
    />
  )
}
