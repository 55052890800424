export const production = {
  env: 'production',
  api: {
    host: 'https://api.fubo.tv',
  },
  config: {
    host: 'https://www.fubo.tv',
  },
  imgixURI: 'https://fubo-landing-prod.imgix.net/',

  // Fubo Imgix path
  imgix: 'https://assets-imgx.fubo.tv',

  amolio: {
    host: 'https://amolio.fubo.tv',
  },

  // segment.io analytics
  segment: {
    publicKey: 'jh2q6sqatv',
  },
}
