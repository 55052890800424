import { Language } from '@fubotv/types/api/appconfig'

// import { cohortingPropertyMapper } from '~/analytics/utils/cohortingPropertyMapper.js' //TODO: Add cohorting
// import { getPageOptions } from '~/reducers/analytics/effects.js' //TODO: verify if needed
// import { AppState } from '~/reducers/index.js' //TODO: are we using state?
// import { getEventContext } from '~/utils/simpleAnalytics.js' //TODO: verify if needed

import { AnalyticsEvent, eventFormatter as eventFormatterV3 } from './v3.ts'
import { environment } from 'src/config/env/env.js'
import { stripNil } from './utils.ts'
import { getPageOptions } from '../context/index.ts'
// import { environment } from '../../../config/environment/index.js'

// this payload maps to the `properties` key of the page_view event
// https://github.com/fubotv/fubo-schema/blob/master/schema/protobuf/services/papi/v3/tracking/page_view.proto

export const fireProtoPageView = async state => {
  //  //TODO: are we using state?
  let data = {}
  if (typeof window !== 'undefined' && window.analytics) {
    try {
      const market = state?.market
      const geolocation = state.geolocation
      const language = state.language
      const eventContext = {
        ...getPageOptions(),
        market,
        // cohorting: cohortingPropertyMapper(state), //TODO: Add Cohorting
      }

      data = eventFormatterV3({
        event: {
          name: 'page_view',
          category: 'user_action',
          subCategory: 'navigation',
          eventContext,
        },
        language: language as Language,
        locationData: {
          asName: undefined,
          asn: undefined,
          connectionType: undefined,
          countryCode: geolocation?.countryCode,
          countryCode2: geolocation?.countryCode2,
          countryName: market,
          displayName: undefined,
          dma: geolocation?.dma,
          ipAddress: undefined,
          networkAllowed: false,
          postalCode: geolocation?.postalCode,
          regionCode: undefined,
        },
      })
      window.analytics.page()
      window.analytics.track('page_view', stripNil(data.properties))
    } catch (err) {
      // environment.environment.env !== 'production' && console.error(err)
      console.error(err)
    }
  }
  return { ...data }
}

export const fireProtoAnalyticsEvent = (
  event: AnalyticsEvent,
  // state: AppState, //TODO: are we using state?
  optionalFields?: { [key: string]: any }
) => {
  if (typeof window !== 'undefined' && window.analytics) {
    try {
      window.analytics.ready(() => {
        const eventContext = {
          // ...getEventContext(window.location?.pathname, state), //
          // cohorting: cohortingPropertyMapper(state), //TODO: Add Cohorting
        }
        event.eventContext = { ...eventContext, ...event?.eventContext }
        const analyticsEvent = eventFormatterV3({ event, ...optionalFields })
        window.analytics.track(event.name, analyticsEvent.properties)
      })
    } catch (err) {
      environment.environment.env !== 'production' && console.error(err)
    }
  }
  return {}
}
