import { JsonValue } from '@protobuf-ts/runtime'

import { cohortingEvent, CohortingEventProperties } from './cohortingEvent.ts'
import { pageViewEvent, PageViewProperties } from './pageView.ts'
import { uiInteractionEvent, UiInteractionProperties } from './uiInteraction.ts'

type Properties = PageViewProperties

type Props = {
  name: string
  data: Properties
  category: string
  subCategory: string
}

export function getEventProto({ name, data, category, subCategory }: Props): JsonValue {
  let properties = null
  switch (name) {
    case 'page_view':
      properties = data as PageViewProperties
      return pageViewEvent({ properties })
    case 'ui_interaction':
      properties = data as UiInteractionProperties
      return uiInteractionEvent({ properties })
    case 'cohorting_event': {
      const properties = data as CohortingEventProperties
      return cohortingEvent({ properties, category, subCategory })
    }
    default:
      return {}
  }
}
