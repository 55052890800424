/* IMGIX Recommended Responsive Design Guides
  https://docs.imgix.com/tutorials/responsive-images-srcset-imgix
  https://docs.imgix.com/tutorials/responsive-images-client-hints
*/

import { isArrayType } from 'src/modules/utils/types'
import { kebabCase } from '../kebabCase'

const imgIX = (src, { isCompress = true, ...rest }) => {
  if (!src.match(/(imgi?x)/)) return src

  const imgixParams = {
    ...rest,
    ch: ['width'],
    auto: isCompress ? ['format', 'compress'] : ['format'],
  }

  const params = Object.keys(imgixParams)
    .reduce((param, key) => {
      const dashed = kebabCase(key)
      const val = imgixParams[key]

      if (typeof val === 'undefined') return param

      const normalized = isArrayType(val) ? val.join(',') : val.toString()

      // no url encode needed for imgix
      param += `&${dashed}=${normalized}`
      return param
    }, '')
    .substring(1)
  return src + (src.indexOf('?') === -1 ? '?' : '&') + params
}

function getAndFormatSrc(url, displaySize) {
  return displaySize === 1 ? `${url} 1x` : `${url}&dpr=${displaySize} ${displaySize}x`
}

function makeSrcSet(url) {
  const displaySizeList = [1, 2, 3, 4]
  const srcSet = displaySizeList.map(displaySize => getAndFormatSrc(url, displaySize))

  return srcSet.join(',')
}

function getImagePath(env = {}, path, isCompress = true, { ...rest }) {
  if (
    path.match(/(imgi?x)/) ||
    path.match(/images(-qa)?\.fubo\.tv/) ||
    path.match(/images(-dev)?\.fubo\.tv/)
  ) {
    return imgIX(path, { isCompress, ...rest })
  }

  if (process.env.NODE_ENV === 'production' && env.imgixURI !== undefined) {
    const baseUrl = env.imgixURI
    const imgixSrc = imgIX(`${baseUrl}${path}`, { isCompress, ...rest })

    return imgixSrc
  } else {
    return '/static/' + path
  }
}

export { imgIX, getAndFormatSrc, makeSrcSet, getImagePath }
