const initialState = {
  analyticsReady: false,
  analyticsPropertiesReady: false,
  pageViewSent: false,
  parameters: null,
  properties: {},
}

export const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ANALYTICS_INIT_SUCCESS':
      return {
        ...state,
        analyticsReady: true,
      }
    case 'UPDATE_ANALYTICS_PROPERTIES':
      return {
        ...state,
        properties: {
          ...state.properties,
          ...action.payload,
        },
      }
    case 'ANALYTICS_PROPERTIES_READY':
      return {
        ...state,
        analyticsPropertiesReady: true,
      }
    case 'UPDATE_PARAMETERS':
      return {
        ...state,
        parameters: {
          ...state.parameters,
          ...action.payload,
        },
      }
    case 'PAGE_VIEW_SENT':
      return {
        ...state,
        pageViewSent: true,
      }
    default:
      return state
  }
}
