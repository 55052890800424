// import { ModalName } from '~/types/modal.js'

export default function getModalContext(modalName) {
  //   switch (modalName) {
  //     case 'ChannelFinderModal':
  //       return {
  //         cta_name: 'view_all_channels',
  //         cta_description: 'welcome_channel_finder_view_all_channels',
  //       }
  //     case 'ConfirmPlanCardModal':
  //       return {
  //         cta_name: '',
  //         cta_description: '',
  //       }
  //     case 'PlanOfferDetailsModal':
  //     case 'ProductDetailsModal':
  //       return {
  //         cta_name: 'learn_more',
  //         cta_description: 'welcome_plan_cards',
  //       }
  //     default:
  //       return {}
  //   }
}
