import {
  UiInteraction,
  UiInteraction_EventName,
  UiInteraction_Properties,
} from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/ui_interaction.ts'
import { JsonValue } from '@protobuf-ts/runtime'
import { v4 as uuid } from 'uuid'

import { formatMessage } from '../utils.ts'

export type UiInteractionProperties = UiInteraction_Properties

/**
 *
 * @function uiInteractionEvent
 * @description Creates a Protobuffer for a ui_interaction event
 * @param {object} input - Object containing property data for ui_interaction
 * track call
 * @returns {UiInteraction} Json UiInteraction Schema
 */
export const uiInteractionEvent = ({
  properties,
}: {
  properties: UiInteractionProperties
}): JsonValue => {
  const message: UiInteraction = {
    event: UiInteraction_EventName.ui_interaction,
    message_id: uuid(),
    properties,
    type: 'track',
  }

  return formatMessage(UiInteraction, message)
}
