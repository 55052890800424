import {
  PageView,
  PageView_EventName,
  PageView_Properties,
} from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/page_view.ts'
import { JsonValue } from '@protobuf-ts/runtime'
import { v4 as uuid } from 'uuid'

import { formatMessage } from '../utils.ts'

export type PageViewProperties = PageView_Properties

/**
 *
 * @function pageViewEvent
 * @description Creates a Protobuffer for a page_view event
 * @param {object} input - Object containing property data for page_view track call
 * @returns {PageView} Json PageView Schema
 */
export const pageViewEvent = ({ properties }: { properties: PageViewProperties }): JsonValue => {
  const message: PageView = {
    event: PageView_EventName.page_view,
    message_id: uuid(),
    properties,
    type: 'track',
  }

  return formatMessage(PageView, message)
}
