import { development } from './mode/development'
import { qa } from './mode/qa'
import { production } from './mode/production'

export function getEnvironment() {
  const fuboEnv = process.env.GATSBY_FUBO_ENV
  if (fuboEnv) {
    switch (fuboEnv) {
      case 'prod':
        return production
      case 'qa':
        return qa
      case 'dev':
        return development
      default:
        return production
    }
  }
  if (process.env.NODE_ENV === 'production' || process.env.GATSBY_ACTIVE_ENV === 'production') {
    return production
  } else {
    return development
  }
}

export const environment = getEnvironment()

// Get Fubo Environment (www.fubo.tv vs qa.fubo.tv)
export const getFuboEnv = () => {
  return process.env.GATSBY_FUBO_ENV
}
