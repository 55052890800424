import { MessageType } from '@protobuf-ts/runtime'

export function parseMessage<T extends object>(
  Schema: MessageType<T>,
  message: T,
  repeatingProperty?: string
) {
  try {
    if (repeatingProperty) {
      /**
       * This is an arbitrary index from the repeating event property in the
       * fubo-schema repo. The iterating functionality lies in the
       * event_properties type (parent), not the singular type (child). Thus,
       * the message is an object with the property key that is iterated over
       * within protobuf. For example, "Cohorting" is a protobuf type that is a
       * single key/value pair. The "CohortingEvent_Properties" parent defines
       * "Cohorting[]" and all other properties. This is why we return only the
       * "repeatingProperty" in the return statement here.
       */
      // @ts-ignore
      return Schema.fromJson(message)[repeatingProperty]
    }

    /**
     * In case of non-repeating properties, we simply pass in the singular
     * type's schema and return that value. This theoretically will never be
     * the event property's parent. For example, this could be "Location" but
     * not "LocationEvent_Properties".
     */
    return Schema.create(Schema.fromJsonString(JSON.stringify(message)))
  } catch (e) {
    console.error('Error parsing protobuf message', e)
  }
}

export function formatMessage<T extends object>(Schema: MessageType<T>, message: T) {
  try {
    return Schema.toJson(message, {
      enumAsInteger: false,
      useProtoFieldName: true,
      emitDefaultValues: true,
    })
  } catch (e) {
    console.error('Error formatting protobuf message', e)
  }
}

/**
 * Until fubo-schema is updated to include the category and subcategory
 * properties, we have to manually add these. Due to this issue, typing
 * errors are being ignored in this method.
 *
 * This method should be completely removed once updated in favor of the
 * `formatMessage` method.
 *
 * @param Schema - The schema type from the v3 spec
 * @param message - The raw analytics object from the v3 event formatter
 */
export function formatCohortingMessage<T extends object>(Schema: MessageType<T>, message: T) {
  const schema = formatMessage(Schema, message)

  if (schema?.constructor === Object) {
    // @ts-expect-error
    ;(schema as object).properties = {
      // @ts-expect-error
      ...schema.properties,
      // @ts-expect-error
      attribute: message.properties.attribute || {},
    }
  }

  return schema
}

export const stripNil = function(obj: any): any {
  for (const prop in obj) {
    if (obj[prop] === undefined || obj[prop] === null) delete obj[prop]
  }
  return obj
}
